const AllProjects = [
  {
    class: "zelfinspectie",
    url: "https://www.zelfinspectie.nl/",
    title: "Zelfinspectie.nl",
    technique: "Drupal, Sass, jQuery, PHP, FPDF",
    boss: "Ontwerpwerk for Ministerie SZW",
    dev: "Development: Nawien Kalidien, Leonie Peters",
    design: "Design: Jeffrey Grin",
  },
  {
    class: "natuurstad",
    url: "https://www.natuurstad.nl/",
    title: "Natuurstad",
    technique: "Drupal, Sass, jQuery",
    boss: "Ontwerpwerk for Natuurstad",
    dev: "Development: Leonie Peters",
  },
  {
    class: "kor-utrecht",
    url: "https://www.kunstinopenbareruimte-utrecht.nl/",
    title: "Kunst in de openbare ruimte Utrecht",
    technique: "Headless Drupal, React, Next.js, Sass, GraphQL",
    boss: "Ontwerpwerk for Gemeente Utrecht",
    dev: "Development: Stef Kors, Leonie Peters",
    design: "Design: Jeffrey Grin",
  },
  {
    class: "nsob",
    url: "https://www.nsob.nl/",
    title: "Nederlandse School voor Openbaar Bestuur",
    technique: "Drupal, Sass, jQuery",
    boss: "Ontwerpwerk for NSOB",
    dev: "Development: Jeroen Harkes, Leonie Peters",
    design: "Design: Jeffrey Grin",
  },
  {
    class: "leraarvanbuiten",
    url: "https://www.leraarvanbuiten.nl/",
    title: "Leraar van buiten",
    technique: "Drupal, Sass, jQuery",
    boss: "Ontwerpwerk for Leraar van Buiten",
    dev: "Development: Leonie Peters",
    design: "Design: Bart Mulder",
  },
  {
    class: "hollandhightech",
    url: "https://www.hollandhightech.nl/",
    title: "Holland High Tech",
    technique: "Drupal, Sass, jQuery",
    boss: "Ontwerpwerk for Holland High Tech",
    dev: "Development: Leonie Peters",
    design: "Design: Ruben Huis in 't Veld",
  },
  {
    class: "ohmmmm",
    url: "https://ohmmmm.netlify.app/",
    title: "Ohmmmm",
    technique: "React, Redux, Phaser, Express, Postgres, Phaser",
    boss: "Codaisseur study project",
    dev: "Development: Rogier Verkaik, Dusty Wood Saunders, Leonie Peters",
    design: "Design: Aliénor de Haan",
  },
  {
    class: "drawbsurd",
    url: "https://drawbsurd.netlify.app/",
    title: "Drawbsurd",
    technique: "React, Redux, TypeScript, Koa, TypeORM, Socket.IO",
    boss: "Codaisseur study project",
    dev: "Development: Izabela Nowosada, Leonie Peters",
  },
];
export default AllProjects;
